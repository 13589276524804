<template >
  <div>
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.replace_products") }} </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <el-button @click="submit()" type="primary" plain>{{$t('message.save')}}</el-button>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->
      

      <div class="card-table-header table-crm-smart custom__scroll">
        <div class="mb-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item
                            :label="$t('message.replace_to_product_id')"
                            class="label_mini"
                            prop="replace_to_product_id"
                        >
                            <el-input
                                type="number"
                                :placeholder="$t('message.replace_to_product_id')"
                                v-model="form.replace_to_product_id"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    
                    <el-col :span="8">      
                        <el-form-item
                            :label="$t('message.delete_replaced_products')"
                            class="label_mini"
                            prop="replaceable_product_id"
                        >
                            <el-checkbox v-model="form.delete_replaced" :label="$t('message.delete_replaced_products')" border></el-checkbox>
                        </el-form-item>
                    </el-col>
                    
                    <el-col :span="24">      
                        <el-form-item
                            :label="$t('message.replaceable_product_id')"
                            class="label_mini"
                        >
                            
                            <el-row :gutter="20">
                                <el-col v-if="form.replaceable_ids && form.replaceable_ids.length > 0" :span="2" v-for="(prod_id, index) in form.replaceable_ids" :key="'id_'+index">
                                    <el-input
                                        type="number"
                                        :placeholder="$t('message.replaceable_product_id')"
                                        v-model="form.replaceable_ids[index]"
                                    ></el-input>
                                </el-col>
                                <el-col :span="2">
                                    <el-button @click="form.replaceable_ids.push(null)" type="primary" icon="el-icon-plus"></el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>

                    </el-col>
                </el-row>
                <el-button v-loading="loadingData" @click="getInfo()" type="primary" icon="el-icon-info">{{ $t('message.information') }}</el-button>

            </el-form>
        </div>
        <div v-if="replace_details && replace_details.replacing_deals && replace_details.replacing_deals.length > 0" class="mb-4">
            <h3>{{ $t('message.deals') }}</h3>
            <div class="ml-5">
                <h3>
                    <span class="mr-2" v-for="deal in replace_details.replacing_deals" :key="'key_'+deal">
                        {{deal}},
                    </span> 
                </h3>
            </div>
        </div>
        
        <h3 v-if="replace_details && replace_details.replaceable && replace_details.replaceable.length > 0">{{ $t('message.products') }}</h3>
        <table
          v-if="replace_details" 
          class="table-my-code table-bordered mb-5"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th v-if="columns.name.show">
                    {{ columns.name.title }}
                </th>

                <th v-if="columns.price.show">
                    {{ columns.price.title }}
                </th>

                <th v-if="columns.weight.show">
                    {{ columns.weight.title }}
                </th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-if="replace_details.main_product" class="cursor-pointer" :key="'main'">
                <td v-if="columns.id.show">
                </td>

                <td v-if="columns.name.show">
                    <span style="font-weight: 700;">{{ $t('message.replace_to_product_id') }}</span>
                </td>

                <td v-if="columns.price.show">
                </td>

                <td v-if="columns.weight.show">
                </td>
            </tr>
            <tr v-if="replace_details.main_product" class="cursor-pointer" :key="'main_data'">
                <td v-if="columns.id.show">
                    {{replace_details.main_product ? replace_details.main_product.id : ''}}
                </td>

                <td v-if="columns.name.show">
                    {{replace_details.main_product ? replace_details.main_product.name : ''}}
                </td>

                <td v-if="columns.price.show">
                    {{replace_details.main_product ? replace_details.main_product.price : ''}} 
                </td>

                <td v-if="columns.weight.show">
                    {{replace_details.main_product ? replace_details.main_product.weight : ''}}  
                </td>
            </tr>
            
            <tr v-if="replace_details && replace_details.replaceable && replace_details.replaceable.length > 0"  class="cursor-pointer" :key="'addition'">
                <td v-if="columns.id.show">
                    
                </td>

                <td v-if="columns.name.show">
                    <span style="font-weight: 700;">{{ $t('message.replaceable_product_id') }}</span>
                </td>

                <td v-if="columns.price.show">
                </td>

                <td v-if="columns.weight.show">
                </td>
            </tr>
            <tr v-if="replace_details && replace_details.replaceable && replace_details.replaceable.length > 0"  
                v-for="product in replace_details.replaceable" 
                :key="product.id" 
                class="cursor-pointer"
            >

                <td v-if="columns.id.show">
                    {{ product.id }}
                </td>

                <td v-if="columns.name.show">
                    {{ product.name }}
                </td>

                <td v-if="columns.price.show">
                    {{ product.price }}
                </td>
               
                <td v-if="columns.weight.show">
                    {{ product.weight }}
                </td>
            </tr>
          </transition-group>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
  import { mapGetters, mapActions } from "vuex";
  import form from "@/utils/mixins/form";
  
  export default {
    
    mixins: [form],
    data() {
      return {
        loadingData: false,
        rules: {
            replace_to_product_id: [
                { required: true, message: this.$t('message.please_enter_input', {input: this.$t('message.replace_to_product_id')}), trigger: 'change' },
            ],
        },
        form: {
            replace_to_product_id: null,
            replaceable_ids: [null],
            delete_replaced: false,
        }
      };
    },
    created() {
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            columns: "products/columns",
            replace_details: "products/replace_details"
        }),
    },
    methods: {
        ...mapActions({
            save: "products/replaceDealProductIds",
            showDetails: "products/replaceProductDetails",
        }),
        getInfo(){
            this.loadingData = true;
            this.showDetails(this.form)
                .then(res => {
                    this.loadingData = false;
                }).catch(err => {
                    this.loadingData = false;
                });
        },
        submit(close = true) {
            this.form.replaceable_ids = this.form.replaceable_ids.filter(el => el);
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if(this.form.replaceable_ids && this.form.replaceable_ids.length > 0){
                        this.$confirm(
                                this.$t('message.do_you_really_want_to_do_this'),
                                this.$t('message.warning'), {
                                    confirmButtonText: this.$t('message.yes'),
                                    cancelButtonText: this.$t('message.no'),
                                    type: "warning"
                                }
                            )
                            .then(() => {
                                this.loadingData = true;
                                this.save(this.form)
                                    .then((res) => {
                                        this.loadingData = false;
                                        this.$alert(res);
                                    })
                                    .catch((err) => {
                                        this.loadingData = false;
                                        this.$alert(err);
                                    });
                            })
                            .catch(() => {
                                this.$message({
                                    type: "warning",
                                    message: this.$t('message.operation_canceled')
                                });
                            });
                        
                    }else{
                        this.$notify({
                            title: this.$t('message.warning'),
                            type: "warning",
                            offset: 130,
                            message: this.$t('message.replacables_not_indicated')
                        }); 
                    }
                    
                }
            });
        },
    },
  };
</script>
  